var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autoSchedulingAdd" },
    [
      _c(
        "el-steps",
        {
          staticClass: "step",
          attrs: {
            active: _vm.step,
            "finish-status": "success",
            "align-center": ""
          }
        },
        [
          _c("el-step", {
            attrs: {
              title: "基本信息",
              description: "选择排班时间、线路、运营方案和班组"
            }
          }),
          _c("el-step", {
            attrs: { title: "排班信息", description: "详细排班信息" }
          }),
          _c("el-step", { attrs: { title: "完成", description: "新增完成" } })
        ],
        1
      ),
      _vm.step === 1
        ? _c("div", [
            _c(
              "div",
              { staticClass: "body mocha-form" },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "demo-form-inline dialog-form",
                    attrs: {
                      model: _vm.formInline,
                      "label-width": "90px",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "排班时段:" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            cellClassName: "timeRangePicker",
                            type: "daterange",
                            clearable: false,
                            "range-separator": "~",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期"
                          },
                          model: {
                            value: _vm.formInline.timeRange,
                            callback: function($$v) {
                              _vm.$set(_vm.formInline, "timeRange", $$v)
                            },
                            expression: "formInline.timeRange"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "线路:" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择线路" },
                            on: { change: _vm.lineSelect },
                            model: {
                              value: _vm.formInline.line,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "line", $$v)
                              },
                              expression: "formInline.line"
                            }
                          },
                          _vm._l(_vm.lineOptions, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "运营方案:" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择运营方案" },
                            on: { change: _vm.selectPlan },
                            model: {
                              value: _vm.formInline.plan,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "plan", $$v)
                              },
                              expression: "formInline.plan"
                            }
                          },
                          _vm._l(_vm.planOptions, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "班组选择:" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: "请选择班组"
                            },
                            model: {
                              value: _vm.formInline.team,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "team", $$v)
                              },
                              expression: "formInline.team"
                            }
                          },
                          _vm._l(_vm.teamOptions, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm.formInline.lineType === 3
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "上下行交替运行:",
                              "label-width": "160px"
                            }
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "true" },
                                model: {
                                  value: _vm.formInline.masterSlaveAlternate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "masterSlaveAlternate",
                                      $$v
                                    )
                                  },
                                  expression: "formInline.masterSlaveAlternate"
                                }
                              },
                              [_vm._v("是")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "false" },
                                model: {
                                  value: _vm.formInline.masterSlaveAlternate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "masterSlaveAlternate",
                                      $$v
                                    )
                                  },
                                  expression: "formInline.masterSlaveAlternate"
                                }
                              },
                              [_vm._v("否")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.formInline.lineType === 3
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "主副场车辆周期互换:",
                              "label-width": "160px"
                            }
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: true },
                                model: {
                                  value: _vm.formInline.banCiRound,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formInline, "banCiRound", $$v)
                                  },
                                  expression: "formInline.banCiRound"
                                }
                              },
                              [_vm._v("是")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: false },
                                model: {
                                  value: _vm.formInline.banCiRound,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formInline, "banCiRound", $$v)
                                  },
                                  expression: "formInline.banCiRound"
                                }
                              },
                              [_vm._v("否")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.formInline.lineType == 3 &&
                    _vm.formInline.banCiRound == true
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "互换模式" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择互换模式" },
                                model: {
                                  value: _vm.formInline.roundType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formInline, "roundType", $$v)
                                  },
                                  expression: "formInline.roundType"
                                }
                              },
                              _vm._l(_vm.roundTypeOptions, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.formInline.lineType == 3 &&
                    _vm.formInline.banCiRound == true &&
                    _vm.formInline.roundType == 0
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "互换日期（每月）:" } },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                size: "small",
                                min: 1,
                                max: 31
                              },
                              model: {
                                value: _vm.formInline.roundMinDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "roundMinDate", $$v)
                                },
                                expression: "formInline.roundMinDate"
                              }
                            }),
                            _vm._v("日、 "),
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                size: "small",
                                min: 1,
                                max: 31
                              },
                              model: {
                                value: _vm.formInline.roundMaxDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "roundMaxDate", $$v)
                                },
                                expression: "formInline.roundMaxDate"
                              }
                            }),
                            _vm._v("日 ")
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.formInline.lineType == 3 &&
                    _vm.formInline.banCiRound == true &&
                    _vm.formInline.roundType == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "周期(天):" } },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                size: "small",
                                min: 1,
                                placeholder: "请输入周期"
                              },
                              model: {
                                value: _vm.formInline.banCiRoundNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "banCiRoundNum", $$v)
                                },
                                expression: "formInline.banCiRoundNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-right buttonGroup" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "default", size: "small" },
                    on: { click: _vm.canceldia }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.nextStep }
                  },
                  [_vm._v("下一步")]
                )
              ],
              1
            )
          ])
        : _vm.step === 2
        ? _c("div", [
            _c(
              "section",
              { staticClass: "step2" },
              [
                _vm._l(_vm.formInline.scheduleData, function(o, i) {
                  return _c("div", { key: i, staticClass: "tableArea" }, [
                    _c("div", { staticClass: "head" }, [
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("组名:")]),
                          _c("el-input", {
                            attrs: { placeholder: "请输入组名" },
                            model: {
                              value: o.groupName,
                              callback: function($$v) {
                                _vm.$set(o, "groupName", $$v)
                              },
                              expression: "o.groupName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("循环方式:")]),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择循环模式" },
                              model: {
                                value: o.shiftMode,
                                callback: function($$v) {
                                  _vm.$set(o, "shiftMode", $$v)
                                },
                                expression: "o.shiftMode"
                              }
                            },
                            _vm._l(_vm.shiftModeOptions, function(shiftMode) {
                              return _c("el-option", {
                                key: shiftMode.value,
                                attrs: {
                                  label: shiftMode.label,
                                  value: shiftMode.value
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "deleteGroup",
                              attrs: {
                                type: "danger",
                                size: "small",
                                disabled:
                                  _vm.formInline.scheduleData.length == 1,
                                plain: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delateScheduleData(i)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "scheduleList" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: o.scheduleList,
                              "header-cell-style": {
                                background: "#F7F8FA",
                                color: "#666666",
                                padding: "6px 0"
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "班次" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请选择班次"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.selectShift(
                                                  $event,
                                                  scope.$index,
                                                  i
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.scheduleNo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "scheduleNo",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.scheduleNo"
                                            }
                                          },
                                          _vm._l(_vm.shiftOptions, function(
                                            shift
                                          ) {
                                            return _c("el-option", {
                                              key: shift.value,
                                              attrs: {
                                                label: shift.label,
                                                value: shift.value,
                                                disabled: shift.isChoosed
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "车辆" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请选择车辆"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.selectVehicle(
                                                  $event,
                                                  scope.$index,
                                                  i
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.vehicelNum,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "vehicelNum",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.vehicelNum"
                                            }
                                          },
                                          _vm._l(_vm.vehicleOptions, function(
                                            vehicle
                                          ) {
                                            return _c("el-option", {
                                              key: vehicle.value,
                                              attrs: {
                                                label: vehicle.label,
                                                value: vehicle.value,
                                                disabled: vehicle.isChoosed
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                              disabled:
                                                o.scheduleList.length == 1
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.deleteRow(
                                                  scope.$index,
                                                  o.scheduleList,
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addLine(i)
                                              }
                                            }
                                          },
                                          [_vm._v("新增")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                }),
                _c(
                  "div",
                  { staticClass: "btnArea" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.addScheduleData }
                      },
                      [_vm._v("新增分组")]
                    )
                  ],
                  1
                )
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "buttonGroup text-right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.prevStep }
                  },
                  [_vm._v("上一步")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      disabled: !_vm.isGeneratePlanVisible
                    },
                    on: { click: _vm.onSubmit }
                  },
                  [_vm._v("生成排班计划")]
                )
              ],
              1
            )
          ])
        : _vm.step === 3
        ? _c("div", [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "buttonGroup text-right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.onAddFinish }
                  },
                  [_vm._v("完成")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step3" }, [_c("span", [_vm._v("完成")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }