var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "schedulingManagement", staticClass: "scheduling-management" },
    [
      _c(
        "div",
        { ref: "functionButtons", staticClass: "function-buttons" },
        [
          _vm.nowMenuList.indexOf("新增") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.toAdd }
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
          _vm.nowMenuList.indexOf("删除") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.delPlan }
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.nowMenuList.indexOf("添加班次车次") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addShiftOrTrip }
                },
                [_vm._v("添加班次车次")]
              )
            : _vm._e(),
          _vm.nowMenuList.indexOf("换班") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.changeDriver(1)
                    }
                  }
                },
                [_vm._v("换班")]
              )
            : _vm._e(),
          _vm.nowMenuList.indexOf("顶班") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.changeDriver(0)
                    }
                  }
                },
                [_vm._v("顶班")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "82px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "排班日期：", prop: "workDay" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      clearable: false,
                      editable: false,
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期"
                    },
                    on: { change: _vm.getBanCiListFn },
                    model: {
                      value: _vm.form.workDay,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "workDay", $$v)
                      },
                      expression: "form.workDay"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属线路：", prop: "companyId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择线路" },
                      on: { change: _vm.getBanCiListFn },
                      model: {
                        value: _vm.form.companyId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "companyId", $$v)
                        },
                        expression: "form.companyId"
                      }
                    },
                    _vm._l(_vm.allLines, function(item) {
                      return _c("el-option", {
                        key: item.companyId,
                        attrs: {
                          label: item.companyName,
                          value: item.companyId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "运行方向：", prop: "moveType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择运行方向", clearable: "" },
                      model: {
                        value: _vm.form.moveType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "moveType", $$v)
                        },
                        expression: "form.moveType"
                      }
                    },
                    _vm._l(_vm.moveTypes, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "班次：", prop: "banCiId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择班次", clearable: "" },
                      model: {
                        value: _vm.form.banCiId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "banCiId", $$v)
                        },
                        expression: "form.banCiId"
                      }
                    },
                    _vm._l(_vm.banciAllList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.toSearch }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        disabled: _vm.checiAllList.length === 0,
                        loading: _vm.downLoadStatus
                      },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "basicBox",
          attrs: { data: _vm.checiAllList, stripe: "", height: "65vh" },
          on: { "selection-change": _vm.getCheckedData }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              selectable: _vm.checkboxT,
              disabled: "true"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "moveType", label: "运行方向" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.moveTypes.find(function(t) {
                          return t.value === scope.row.moveType
                        }).label
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "banCiName", label: "班次" }
          }),
          _c("el-table-column", {
            attrs: { prop: "beginTime", label: "计划发车" }
          }),
          _c("el-table-column", {
            attrs: { prop: "endTime", label: "计划到达" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(_vm._f("filterArrive")(scope.row)))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "workTime", label: "行驶时长" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cph", label: "车牌号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#01adff", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.showScheduling(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.cph))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "driverName", label: "司机" }
          }),
          _c("el-table-column", {
            attrs: { label: "车次状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.adjustStatus == 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("filterAdjustStatus")(
                                  scope.row.adjustStatus
                                )
                              ) +
                              " "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterAdjustStatus")(
                                scope.row.adjustStatus
                              )
                            )
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "natureState", label: "变更说明" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.natureState > 2 && scope.row.natureState < 6
                      ? _c("span", { staticStyle: { color: "#0053e1" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterNatureState")(scope.row.natureState)
                            )
                          )
                        ])
                      : _vm._e(),
                    scope.row.natureState == 6
                      ? _c("span", { staticStyle: { color: "#acb2bc" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("filterNatureState")(
                                  scope.row.natureState
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    scope.row.natureState == 7
                      ? _c("span", { staticStyle: { color: "#2dbff7" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("filterNatureState")(
                                  scope.row.natureState
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    scope.row.natureState < 3 || scope.row.natureState > 7
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("filterNatureState")(
                                  scope.row.natureState
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "remark", label: "备注" } })
        ],
        1
      ),
      _vm.driverdialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.updateform.updateType == 1 ? "换班" : "顶班",
                visible: _vm.driverdialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.driverdialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form search-area" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "updateform",
                      staticClass: "demo-form form-search updateform",
                      attrs: {
                        model: _vm.updateform,
                        "status-icon": "",
                        rules: _vm.updaterules,
                        "label-position": "left",
                        "label-width": "120px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { label: "方式:", prop: "classType" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择方式" },
                              model: {
                                value: _vm.updateform.classType,
                                callback: function($$v) {
                                  _vm.$set(_vm.updateform, "classType", $$v)
                                },
                                expression: "updateform.classType"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "车人并换", value: "1" }
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    _vm.updateform.updateType == 1
                                      ? "司机换班"
                                      : "司机顶班",
                                  value: "2"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    _vm.updateform.updateType == 1
                                      ? "车辆换班"
                                      : "车辆顶班",
                                  value: "3"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { label: "现车辆:", prop: "oldVehicleNo" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: true },
                              model: {
                                value: _vm.updateform.oldVehicleNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.updateform, "oldVehicleNo", $$v)
                                },
                                expression: "updateform.oldVehicleNo"
                              }
                            },
                            _vm._l(_vm.nowBusByline, function(item) {
                              return _c("el-option", {
                                key: item.seqId,
                                attrs: {
                                  label: item.busCode,
                                  value: item.seqId
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { label: "现司机:", prop: "oldCredentialsNo" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: true },
                              model: {
                                value: _vm.updateform.oldCredentialsNo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.updateform,
                                    "oldCredentialsNo",
                                    $$v
                                  )
                                },
                                expression: "updateform.oldCredentialsNo"
                              }
                            },
                            _vm._l(_vm.nowDriverByline, function(item) {
                              return _c("el-option", {
                                key: item.seqId,
                                attrs: {
                                  label: item.driverName,
                                  value: item.seqId
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.updateform.updateType == 0
                        ? _c(
                            "div",
                            [
                              _vm.updateform.classType != "2"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        label: "顶班车辆:",
                                        prop: "newVehicleNo"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.updateform.classType == "2"
                                          },
                                          model: {
                                            value: _vm.updateform.newVehicleNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.updateform,
                                                "newVehicleNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateform.newVehicleNo"
                                          }
                                        },
                                        _vm._l(_vm.allBusByline, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.vehicleNo,
                                            attrs: {
                                              label: item.cph,
                                              value: item.vehicleNo
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.updateform.classType != "3"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        label: "顶班司机:",
                                        prop: "newCredentialsNo"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value:
                                              _vm.updateform.newCredentialsNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.updateform,
                                                "newCredentialsNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateform.newCredentialsNo"
                                          }
                                        },
                                        _vm._l(_vm.allDriverByline, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.credentialsNo,
                                            attrs: {
                                              label: item.driverName,
                                              value: item.credentialsNo
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.updateform.updateType == 1
                        ? _c(
                            "div",
                            [
                              _vm.updateform.classType == "3"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        label: "换班车辆:",
                                        prop: "newVehicleNo"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.updateform.classType == "2"
                                          },
                                          model: {
                                            value: _vm.updateform.newVehicleNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.updateform,
                                                "newVehicleNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateform.newVehicleNo"
                                          }
                                        },
                                        _vm._l(_vm.allBusByline, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.vehicleNo,
                                            attrs: {
                                              label: item.cph,
                                              value: item.vehicleNo
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.updateform.classType == "2"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        label: "换班司机:",
                                        prop: "newCredentialsNo"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value:
                                              _vm.updateform.newCredentialsNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.updateform,
                                                "newCredentialsNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateform.newCredentialsNo"
                                          }
                                        },
                                        _vm._l(_vm.allDriverByline, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.credentialsNo,
                                            attrs: {
                                              label: item.driverName,
                                              value: item.credentialsNo
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.updateform.classType == "1"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        label: "换班司机-车辆:",
                                        prop: "newCredentialsNo"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value:
                                              _vm.updateform.newCredentialsNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.updateform,
                                                "newCredentialsNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateform.newCredentialsNo"
                                          }
                                        },
                                        _vm._l(_vm.allDriverBusByline, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key:
                                              item.credentialsNo +
                                              "-" +
                                              item.vehicleNo,
                                            attrs: {
                                              label:
                                                item.driverName +
                                                "-" +
                                                item.cph,
                                              value:
                                                item.credentialsNo +
                                                "-" +
                                                item.vehicleNo
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.updateform.updateType != 1
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "90%" },
                              attrs: { label: "车次:", prop: "checi" }
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "basicBox",
                                  attrs: {
                                    data: _vm.dingAllList,
                                    "header-cell-style": {
                                      background: "#F7F8FA",
                                      color: "#666666",
                                      padding: "0"
                                    }
                                  },
                                  on: {
                                    "selection-change": _vm.getDingCheckedData
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "selection", width: "55" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "beginTime",
                                      label: "计划发车"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "endTime",
                                      label: "计划到达"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("filterDingArrive")(
                                                      scope.row
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2977254005
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "moveType",
                                      label: "发车类型"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttonGroup text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.driverdialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  !_vm.btnShow
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.update }
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "55%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("autoSchedulingAdd", {
                on: { closedia: _vm.closedia, canceldia: _vm.canceldia }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.schedulingVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "单车发班表",
                visible: _vm.schedulingVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.schedulingVisible = $event
                }
              }
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.busPlanList, height: "65vh" } },
                [
                  _c(
                    "el-table-column",
                    { attrs: { prop: "time", label: _vm.busCode } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: _vm.banCiMoveType == 2 ? "down" : "up",
                          label: _vm.banCiMoveType == 2 ? "主-副" : "副-主"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: _vm.banCiMoveType == 2 ? "up" : "down",
                          label: _vm.banCiMoveType == 2 ? "副-主" : "主-副"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.delPlanVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "删除计划",
                visible: _vm.delPlanVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.delPlanVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form search-area" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "demo-form form-search",
                      attrs: {
                        model: _vm.delform,
                        "label-position": "left",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "变更说明:", prop: "natureState" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择变更说明" },
                              model: {
                                value: _vm.delform.natureState,
                                callback: function($$v) {
                                  _vm.$set(_vm.delform, "natureState", $$v)
                                },
                                expression: "delform.natureState"
                              }
                            },
                            _vm._l(_vm.natureOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictCode,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictCode
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注:", prop: "remark" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.delform.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.delform, "remark", $$v)
                              },
                              expression: "delform.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttonGroup text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.delPlanVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.submitDelPlan }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "addShiftOrTripDia",
          attrs: {
            title: "添加班次/车次",
            visible: _vm.addShiftOrTripDia,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.addShiftOrTripDia = $event
            },
            close: _vm.cancleAddShiftOrTripDia,
            open: function($event) {
              _vm.addShiftOrTripDia = true
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.shift2radio },
              model: {
                value: _vm.addShiftOrTripRadio,
                callback: function($$v) {
                  _vm.addShiftOrTripRadio = $$v
                },
                expression: "addShiftOrTripRadio"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "添加班次", name: "1" } }, [
                _c(
                  "div",
                  { staticClass: "form search-area" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "demo-form dialog-form",
                        attrs: {
                          model: _vm.addShiftOrTripForm,
                          "label-position": "left",
                          "label-width": "120px",
                          rules: _vm.addShiftOrTripRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "线路:", prop: "line" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.chooseLine },
                                model: {
                                  value: _vm.addShiftOrTripForm.line,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "line",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.line"
                                }
                              },
                              _vm._l(_vm.allLines, function(item) {
                                return _c("el-option", {
                                  key: item.companyId,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.companyId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "日期:", prop: "date" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.datePickerOptions
                              },
                              model: {
                                value: _vm.addShiftOrTripForm.date,
                                callback: function($$v) {
                                  _vm.$set(_vm.addShiftOrTripForm, "date", $$v)
                                },
                                expression: "addShiftOrTripForm.date"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "车辆:", prop: "vehicle" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.filterMethodFun,
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.addShiftOrTripForm.vehicle,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "vehicle",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.vehicle"
                                }
                              },
                              _vm._l(_vm.vehicleOptions, function(item) {
                                return _c("el-option", {
                                  key: item.vehicleNo,
                                  attrs: {
                                    label: item.cph,
                                    value: item.vehicleNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "驾驶员:", prop: "vehicle" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.vehicleMethodFun,
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.addShiftOrTripForm.driver,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "driver",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.driver"
                                }
                              },
                              _vm._l(_vm.driversOptions, function(item) {
                                return _c("el-option", {
                                  key: item.credentialsNo,
                                  attrs: {
                                    label: item.driverName,
                                    value: item.credentialsNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发车类型:", prop: "type" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.addShiftOrTripForm.type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "type",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.type"
                                }
                              },
                              [_vm._v("主-副")]
                            ),
                            _vm.LineType !== 1
                              ? _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "2" },
                                    model: {
                                      value: _vm.addShiftOrTripForm.type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addShiftOrTripForm,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "addShiftOrTripForm.type"
                                    }
                                  },
                                  [_vm._v("副-主")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发车时间:", prop: "time" } },
                          [
                            _c("el-time-picker", {
                              attrs: {
                                "value-format": "HH:mm",
                                placeholder: "请选择发车时间"
                              },
                              model: {
                                value: _vm.addShiftOrTripForm.time,
                                callback: function($$v) {
                                  _vm.$set(_vm.addShiftOrTripForm, "time", $$v)
                                },
                                expression: "addShiftOrTripForm.time"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            ref: "runTime",
                            attrs: { label: "运行时长(分钟):", prop: "runTime" }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addShiftOrTripForm.runTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addShiftOrTripForm,
                                    "runTime",
                                    $$v
                                  )
                                },
                                expression: "addShiftOrTripForm.runTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            ref: "restTime",
                            attrs: {
                              label: "休息时长(分钟):",
                              prop: "restTime"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addShiftOrTripForm.restTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addShiftOrTripForm,
                                    "restTime",
                                    $$v
                                  )
                                },
                                expression: "addShiftOrTripForm.restTime"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.addShiftOrTripRadio == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { label: "增加趟次:", prop: "addTrips" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.addShiftOrTripForm.addTrips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addShiftOrTripForm,
                                          "addTrips",
                                          $$v
                                        )
                                      },
                                      expression: "addShiftOrTripForm.addTrips"
                                    }
                                  },
                                  _vm._l(_vm.addTripsOptions, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-tab-pane", { attrs: { label: "添加车次", name: "2" } }, [
                _c(
                  "div",
                  { staticClass: "form search-area" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "demo-form dialog-form",
                        attrs: {
                          model: _vm.addShiftOrTripForm,
                          "label-position": "left",
                          "label-width": "120px",
                          rules: _vm.addShiftOrTripRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "线路:", prop: "line" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.chooseLine },
                                model: {
                                  value: _vm.addShiftOrTripForm.line,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "line",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.line"
                                }
                              },
                              _vm._l(_vm.allLines, function(item) {
                                return _c("el-option", {
                                  key: item.companyId,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.companyId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "日期:", prop: "date" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.datePickerOptions
                              },
                              model: {
                                value: _vm.addShiftOrTripForm.date,
                                callback: function($$v) {
                                  _vm.$set(_vm.addShiftOrTripForm, "date", $$v)
                                },
                                expression: "addShiftOrTripForm.date"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "车辆:", prop: "vehicle" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.filterMethodFun,
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.addShiftOrTripForm.vehicle,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "vehicle",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.vehicle"
                                }
                              },
                              _vm._l(_vm.vehicleOptions, function(item) {
                                return _c("el-option", {
                                  key: item.vehicleNo,
                                  attrs: {
                                    label: item.cph,
                                    value: item.vehicleNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "驾驶员:", prop: "vehicle" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.vehicleMethodFun,
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.addShiftOrTripForm.driver,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "driver",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.driver"
                                }
                              },
                              _vm._l(_vm.driversOptions, function(item) {
                                return _c("el-option", {
                                  key: item.credentialsNo,
                                  attrs: {
                                    label: item.driverName,
                                    value: item.credentialsNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发车类型:", prop: "type" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.addShiftOrTripForm.type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "type",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.type"
                                }
                              },
                              [_vm._v("主-副")]
                            ),
                            _vm.LineType !== 1
                              ? _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "2" },
                                    model: {
                                      value: _vm.addShiftOrTripForm.type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addShiftOrTripForm,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "addShiftOrTripForm.type"
                                    }
                                  },
                                  [_vm._v("副-主")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发车时间:", prop: "time" } },
                          [
                            _c("el-time-picker", {
                              attrs: {
                                "value-format": "HH:mm",
                                placeholder: "请选择发车时间"
                              },
                              model: {
                                value: _vm.addShiftOrTripForm.time,
                                callback: function($$v) {
                                  _vm.$set(_vm.addShiftOrTripForm, "time", $$v)
                                },
                                expression: "addShiftOrTripForm.time"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            ref: "runTime",
                            attrs: { label: "运行时长(分钟):", prop: "runTime" }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addShiftOrTripForm.runTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addShiftOrTripForm,
                                    "runTime",
                                    $$v
                                  )
                                },
                                expression: "addShiftOrTripForm.runTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            ref: "restTime",
                            attrs: {
                              label: "休息时长(分钟):",
                              prop: "restTime"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addShiftOrTripForm.restTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addShiftOrTripForm,
                                    "restTime",
                                    $$v
                                  )
                                },
                                expression: "addShiftOrTripForm.restTime"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.addShiftOrTripRadio == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { label: "增加趟次:", prop: "addTrips" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.addShiftOrTripForm.addTrips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addShiftOrTripForm,
                                          "addTrips",
                                          $$v
                                        )
                                      },
                                      expression: "addShiftOrTripForm.addTrips"
                                    }
                                  },
                                  _vm._l(_vm.addTripsOptions, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttonGroup text-right tabButtonGroup" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: { click: _vm.cancleAddShiftOrTripDia }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: !_vm.isSubmitShiftOrTripVisible
                  },
                  on: { click: _vm.submitShiftOrTrip }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }