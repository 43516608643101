<template>
  <div class="autoSchedulingAdd">
    <el-steps :active="step" finish-status="success" align-center class="step">
      <el-step title="基本信息" description="选择排班时间、线路、运营方案和班组"></el-step>
      <el-step title="排班信息" description="详细排班信息"></el-step>
      <el-step title="完成" description="新增完成"></el-step>
    </el-steps>

    <div v-if="step === 1">
      <div class="body mocha-form">
        <el-form :model="formInline" label-width="90px" label-position="left" class="demo-form-inline dialog-form">
          <el-form-item label="排班时段:">
            <el-date-picker
              cellClassName="timeRangePicker"
              v-model="formInline.timeRange"
              type="daterange"
              :clearable="false"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="线路:">
            <el-select v-model="formInline.line" @change="lineSelect" placeholder="请选择线路">
              <el-option
                v-for="item in lineOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运营方案:">
            <el-select v-model="formInline.plan" placeholder="请选择运营方案" @change="selectPlan">
              <el-option
                v-for="item in planOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班组选择:">
            <el-select v-model="formInline.team" multiple collapse-tags placeholder="请选择班组">
              <el-option
                v-for="item in teamOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上下行交替运行:" label-width="160px" v-if="formInline.lineType === 3">
            <el-radio v-model="formInline.masterSlaveAlternate" label="true">是</el-radio>
            <el-radio v-model="formInline.masterSlaveAlternate" label="false">否</el-radio>
          </el-form-item>
          <el-form-item label="主副场车辆周期互换:" label-width="160px" v-if="formInline.lineType === 3">
            <el-radio v-model="formInline.banCiRound" :label="true">是</el-radio>
            <el-radio v-model="formInline.banCiRound" :label="false">否</el-radio>
          </el-form-item>
          <el-form-item
            label="互换模式"
            v-if="formInline.lineType == 3 && formInline.banCiRound == true"
          >
            <el-select v-model="formInline.roundType" placeholder="请选择互换模式">
              <el-option
                v-for="item in roundTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="互换日期（每月）:"
            v-if="formInline.lineType == 3 && formInline.banCiRound == true && formInline.roundType == 0"
          >
            <el-input-number
              controls-position="right"
              size="small"
              :min="1"
              :max="31"
              v-model="formInline.roundMinDate"
            ></el-input-number>日、
            <el-input-number
              controls-position="right"
              size="small"
              :min="1"
              :max="31"
              v-model="formInline.roundMaxDate"
            ></el-input-number>日
          </el-form-item>
          <el-form-item
            label="周期(天):"
            v-if="formInline.lineType == 3 && formInline.banCiRound == true && formInline.roundType == 1"
          >
            <el-input-number
              controls-position="right"
              size="small"
              :min="1"
              v-model="formInline.banCiRoundNum"
              placeholder="请输入周期"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div class="text-right buttonGroup">
        <el-button type="default" size="small" @click="canceldia">取消</el-button>
        <el-button type="primary" size="small" @click="nextStep">下一步</el-button>
      </div>
    </div>
    <div v-else-if="step===2">
      <section class="step2">
        <div class="tableArea" v-for="(o,i) in formInline.scheduleData" :key="i">
          <div class="head">
            <div class="item">
              <span>组名:</span>
              <el-input v-model="o.groupName" placeholder="请输入组名"></el-input>
            </div>
            <div class="item">
              <span>循环方式:</span>
              <el-select v-model="o.shiftMode" placeholder="请选择循环模式">
                <el-option
                  v-for="shiftMode in shiftModeOptions"
                  :key="shiftMode.value"
                  :label="shiftMode.label"
                  :value="shiftMode.value"
                ></el-option>
              </el-select>
            </div>
            <div class="item">
              <el-button
                type="danger"
                size="small"
                :disabled="formInline.scheduleData.length==1"
                class="deleteGroup"
                plain
                @click="delateScheduleData(i)"
              >删除</el-button>
            </div>
          </div>
          <div class="scheduleList">
            <el-table
              :data="o.scheduleList"
              :header-cell-style="{ background: '#F7F8FA', color: '#666666',padding: '6px 0' }"
            >
              <el-table-column label="班次">
                <template slot-scope="scope">
                  <el-select
                    clearable
                    v-model="scope.row.scheduleNo"
                    @change="selectShift($event,scope.$index,i)"
                    placeholder="请选择班次"
                  >
                    <el-option
                      v-for="shift in shiftOptions"
                      :key="shift.value"
                      :label="shift.label"
                      :value="shift.value"
                      :disabled="shift.isChoosed"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="车辆">
                <template slot-scope="scope">
                  <el-select
                    clearable
                    v-model="scope.row.vehicelNum"
                    @change="selectVehicle($event,scope.$index,i)"
                    placeholder="请选择车辆"
                  >
                    <el-option
                      v-for="vehicle in vehicleOptions"
                      :key="vehicle.value"
                      :label="vehicle.label"
                      :value="vehicle.value"
                      :disabled="vehicle.isChoosed"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    :disabled="o.scheduleList.length==1"
                    @click.native.prevent="deleteRow(scope.$index, o.scheduleList,scope.row)"
                  >删除</el-button>
                  <el-button type="text" size="small" @click="addLine(i)">新增</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="btnArea">
          <el-button type="primary" size="small" @click="addScheduleData">新增分组</el-button>
        </div>
      </section>
      <div class="buttonGroup text-right">
        <el-button type="primary" size="small" @click="prevStep">上一步</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSubmit"
          :disabled="!isGeneratePlanVisible"
        >生成排班计划</el-button>
      </div>
    </div>
    <div v-else-if="step===3">
      <div class="step3">
        <!-- <el-image style="width:7vh;height:7vh;" src="/static/bus/images/success.png"></el-image> -->
        <span>完成</span>
      </div>
      <div class="buttonGroup text-right">
        <el-button type="primary" size="small" @click="onAddFinish">完成</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryAllBusLineList,
  queryOperateByLineId,
  getAutoArrangeConfig,
  getTeamByLineId,
  getBusList,
  getOperateList,//获取运营方案列表
  getCheCi,
  getBanCi,
  getBusPlanList,
  addScheduling
} from '@/api/lib/bus-api.js'
import { formatDay } from "@/common/utils/index";

export default {
  data() {
    return {
      formInline: {
        timeRange: [],
        line: "",
        lineType: "",
        plan: "",
        team: [],
        banCiRound: false, // 周期互换
        masterSlaveAlternate: "false",
        cycle: "", // 周期
        scheduleData: [
          {
            groupName: "",
            shiftMode: 0,
            scheduleList: [
              {
                scheduleNo: "",
                vehicelNum: ""
              }
            ]
          }
        ]
      },
      driverTeamIds: [],
      lineOptions: [],
      planOptions: [],
      teamOptions: [],
      roundTypeOptions: [
        {
          value: 0,
          label: "指定日期"
        },
        {
          value: 1,
          label: "指定周期"
        }
      ],
      shiftModeOptions: [
        {
          value: 0,
          label: "顺时针"
        },
        {
          value: 1,
          label: "逆时针"
        },
        {
          value: 2,
          label: "固定"
        }
      ],
      shiftOptions: [],//班次列表
      vehicleOptions: [],
      step: 1,
      isGeneratePlanVisible: true
    };
  },
  computed: {
    // nextStepAvailable() {
    //   if (
    //     this.formInline.timeRange.length == 0 ||
    //     this.formInline.team.length == 0 ||
    //     this.shiftOptions.length > this.vehicleOptions.length ||
    //     !this.formInline.plan
    //   ) {
    //     return false;
    //   } else {
    //     if (this.formInline.lineType == 3) {
    //       if (this.formInline.banCiRound == true) {
    //         if (this.formInline.roundType == 0) {
    //           if (
    //             this.formInline.roundMaxDate &&
    //             this.formInline.roundMinDate
    //           ) {
    //             return true;
    //           } else {
    //             return false;
    //           }
    //         } else if (this.formInline.roundType == 1) {
    //           if (this.formInline.banCiRoundNum) {
    //             return true;
    //           } else {
    //             return false;
    //           }
    //         }
    //       } else {
    //         return true;
    //       }
    //     } else {
    //       return true;
    //     }
    //   }
    // }
  },
  methods: {
    canceldia() {
      this.$emit("canceldia");
    },
    //生成排班计划
    onSubmit() {
      this.isGeneratePlanVisible = false;
      let flag = true;
      // 日期必须选择
      if(this.formInline.timeRange.length == 0){
        this.isGeneratePlanVisible = true;
        this.$message.warning("请完选择日期");
        return
      }
      // 班次必须全部选择
      // this.shiftOptions.forEach(item => {
      //   if (item.useLine == "") {
      //     flag = false;
      //   }
      // });
      // 表格非空校验
      this.formInline.scheduleData.forEach(item => {
        if (item.groupName == "" || item.shiftMode === "") {
          flag = false;
        } else {
          item.scheduleList.forEach(o => {
            if (o.scheduleNo == "" || o.vehicelNum == "") {
              flag = false;
            }
          });
        }
      });
      // 新增排班
      if (flag) {
        // 拼装请求参数
        let schedulingTeams = [];
        this.formInline.scheduleData.forEach(item => {
          let binds = [];
          item.scheduleList.forEach(o => {
            binds.push({
              vehicleNo: o.vehicelNum,
              stationNo: o.scheduleNo
            });
          });
          let temp = {
            teamName: item.groupName,
            workShifts: item.shiftMode,
            binds
          };
          schedulingTeams.push(temp);
        });
        this.formInline.roundDates = [];
        if (this.formInline.roundMinDate != null) {
          this.formInline.roundDates.push(this.formInline.roundMinDate);
          this.formInline.roundDates.push(this.formInline.roundMaxDate);
        }
        let data = {
          startTime: formatDay(this.formInline.timeRange[0]),
          endTime: formatDay(this.formInline.timeRange[1]),
          masterSlaveAlternate: this.formInline.masterSlaveAlternate,
          teamIds: this.formInline.team,
          operateId: this.formInline.plan,
          lineId: this.formInline.line,
          schedulingTeams,
          banCiRound: this.formInline.banCiRound,
          roundType: this.formInline.roundType,
          banCiRoundNum: this.formInline.banCiRoundNum,
          roundDates: this.formInline.roundDates
        };
        addScheduling({ ...data })
          .then(res => {
            this.isGeneratePlanVisible = true;
            if (res.code == 1000) {
              this.step++;
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(error => {
            this.isGeneratePlanVisible = true;
          });
      } else {
        this.$message.warning("请完整填写表格");
        this.isGeneratePlanVisible = true;
      }
    },
    // 下一步
    nextStep() {
      // if (this.shiftOptions.length > this.vehicleOptions.length) {
      //   this.$message.warning(
      //     "当前所选班组的车辆数少于运营方案中计划班次的车辆数，请按计划添加班组！"
      //   );
      // } else {
        // 如果方案班组变化，第二步回显disabled
        if (this.step == 1) {
          if (
            JSON.stringify(this.driverTeamIds) ==
            JSON.stringify(this.formInline.team)
          ) {
            this.shiftOptions.forEach(item => {
              this.formInline.scheduleData.forEach((a, aindex) => {
                a.scheduleList.forEach((b, bindex) => {
                  if (item.value == b.scheduleNo) {
                    item.isChoosed = true;
                    item.useLine = aindex + "-" + bindex;
                  }
                });
              });
            });
            this.vehicleOptions.forEach(item => {
              this.formInline.scheduleData.forEach((a, aindex) => {
                a.scheduleList.forEach((b, bindex) => {
                  if (item.value == b.vehicelNum) {
                    item.isChoosed = true;
                    item.useLine = aindex + "-" + bindex;
                  }
                });
              });
            });
          } else {
            this.resetShiftList();
            // 获取班次信息
            this.shiftOptions = [];
            getOperateList({ operateId: this.formInline.plan }).then(res => {
              if (res.code == 1000) {
                res.data.forEach(shift => {
                  this.shiftOptions.push({
                    value: shift.banciNo,
                    label: shift.banciNo,
                    isChoosed: false,
                    useLine: ""
                  });
                });
              }
            });
          }
          this.driverTeamIds = this.formInline.team;
        }
        this.step++;
      // }
    },
    // 上一步
    prevStep() {
      this.step--;
    },
    // 完成新增排班
    onAddFinish() {
      this.$emit("closedia", {
        line: this.formInline.line
      });
    },
    // 获取所属线路列表信息
    getAllLine() {
      queryAllBusLineList({}).then(res => {
        if (res.code == 1000) {
          res.data.forEach(line => {
            this.lineOptions.push({
              value: line.companyId,
              label: line.companyName,
              lineType: line.lineType
            });
          });
        }
      });
    },
    // 选择线路后查询运营方案及下行班组
    lineSelect() {
      // 清空运营方案和班组
      this.formInline.plan = "";
      this.formInline.team = [];
      this.planOptions = [];
      this.teamOptions = [];
      // 清空排班列表
      // this.resetShiftList();
      // 获取运营方案
      queryOperateByLineId({ lineId: this.formInline.line }).then(res => {
        if (res.code == 1000) {
          res.data.forEach(plan => {
            this.planOptions.push({
              value: plan.id,
              label: plan.name
            });
          });
        }
      });
      // 获取班组
      getTeamByLineId({ lineId: this.formInline.line }).then(res => {
        if (res.code == 1000) {
          res.data.forEach(team => {
            this.teamOptions.push({
              value: team.id,
              label: team.name
            });
          });
        }
      });
    },
    // 选择运营方案
    selectPlan() {
      // 选择运营方案时清空排班列表
      this.searchShiftList();
      // this.resetShiftList();
      // 获取班次信息
      this.shiftOptions = [];
      getOperateList({ operateId: this.formInline.plan }).then(res => {
        if (res.code == 1000) {
          res.data.forEach(shift => {
            this.shiftOptions.push({
              value: shift.banciNo,
              label: shift.banciNo,
              isChoosed: false,
              useLine: ""
            });
          });
        }
      });
    },
    // 新增排班列表
    addLine(i) {
      this.formInline.scheduleData[i].scheduleList.push({
        scheduleNo: "",
        vehicelNum: ""
      });
    },
    // 删除排班列表行
    deleteRow(index, rows, scope) {
      // 改变当前行所选中的班次,车辆的选中状态和选中的行数
      this.shiftOptions.forEach(item => {
        if (item.value == scope.scheduleNo) {
          item.isChoosed = false;
          item.useLine = "";
        }
      });

      this.vehicleOptions.forEach(item => {
        if (item.value == scope.vehicelNum) {
          item.isChoosed = false;
          item.useLine = "";
        }
      });

      // 删除行时将该行所在的组所使用的班次/车辆信息修改
      for (let i = index + 1; i < rows.length; i++) {
        if (rows[i].scheduleNo) {
          let shift = this.shiftOptions[
            this.shiftOptions.findIndex(
              item => item.value == rows[i].scheduleNo
            )
          ];

          shift.useLine = `${shift.useLine.split("-")[0]}-${shift.useLine.split(
            "-"
          )[1] - 1}`;
        }

        if (rows[i].vehicelNum) {
          let vehicle = this.vehicleOptions[
            this.vehicleOptions.findIndex(
              item => item.value == rows[i].vehicelNum
            )
          ];

          vehicle.useLine = `${
            vehicle.useLine.split("-")[0]
          }-${vehicle.useLine.split("-")[1] - 1}`;
        }
      }
      rows.splice(index, 1);
    },
    // 新增分组
    addScheduleData() {
      this.formInline.scheduleData.push({
        groupName: "",
        shiftMode: 0,
        scheduleList: [
          {
            scheduleNo: "",
            vehicelNum: ""
          }
        ]
      });
    },
    // 删除分组
    delateScheduleData(i) {
      // 删除分组前将分组中存在的班次/车辆信息的状态重置
      this.formInline.scheduleData[i].scheduleList.forEach(o => {
        let shift = this.shiftOptions[
          this.shiftOptions.findIndex(s => s.value == o.scheduleNo)
        ];
        if (shift) {
          shift.useLine = "";
          shift.isChoosed = false;
        }
        let vehicle = this.vehicleOptions[
          this.vehicleOptions.findIndex(s => s.value == o.vehicelNum)
        ];
        if (vehicle) {
          vehicle.useLine = "";
          vehicle.isChoosed = false;
        }
      });
      this.formInline.scheduleData.splice(i, 1);
    },
    // 选择班次
    selectShift(v, r, i) {
      // 将班次列表中被当前行所用的项重置为未被使用,未被选中状态
      this.shiftOptions.forEach(item => {
        if (item.useLine == `${i}-${r}`) {
          item.useLine = "";
          item.isChoosed = false;
        }
      });
      this.shiftOptions.forEach(item => {
        if (item.value == v) {
          item.isChoosed = true;
          // 存储使用该值的行数用来标记
          item.useLine = `${i}-${r}`;
        }
      });
    },
    // 选择车辆
    selectVehicle(v, r, i) {
      // 将车辆列表中被当前行所用的项重置为未被使用,未被选中状态
      this.vehicleOptions.forEach(item => {
        if (item.useLine == `${i}-${r}`) {
          item.useLine = "";
          item.isChoosed = false;
        }
      });
      this.vehicleOptions.forEach(item => {
        if (item.value == v) {
          item.isChoosed = true;
          // 存储使用该值的行数用来标记
          item.useLine = `${i}-${r}`;
        }
      });
    },
    // 查询生成排班时方案上次的配置项
    searchShiftList() {
      getAutoArrangeConfig({ operateId: this.formInline.plan }).then(res => {
        if (res.data.length == 0) {
          this.formInline.scheduleData = [
            {
              groupName: "",
              shiftMode: 0,
              scheduleList: [
                {
                  scheduleNo: "",
                  vehicelNum: ""
                }
              ]
            }
          ];
        } else {
          this.formInline.scheduleData = [];

          res.data.forEach(item => {
            var scheduleList = [];
            item.bands.forEach(element => {
              scheduleList.push({
                vehicelNum: element.vehicleNo,
                scheduleNo: element.stationNo
              });
            });
            this.formInline.scheduleData.push({
              groupName: item.name,
              shiftMode: item.workShifts,
              scheduleList: scheduleList
            });
          });
          this.driverTeamIds = res.data[0].teamIds;
          this.formInline.team = res.data[0].teamIds;
          // 班组被删除后清空
          var arr = []
          this.teamOptions.forEach(item=>{
            arr.push(item.value)
          })
          res.data[0].teamIds.forEach(item=>{
            if(arr.indexOf(item) == -1){
              this.formInline.team = []
            }
          })
          console.log(333,this.driverTeamIds,this.formInline.team)
        }
      });
    },
    // 重置排班列表
    resetShiftList() {
      this.formInline.scheduleData = [
        {
          groupName: "",
          shiftMode: 0,
          scheduleList: [
            {
              scheduleNo: "",
              vehicelNum: ""
            }
          ]
        }
      ];
      this.shiftOptions = [];
      // this.vehicleOptions = [];

      // 重置车辆列表使用的标识
      this.vehicleOptions.forEach(item => {
        item.useLine = "";
        item.isChoosed = false;
      });
    }
  },
  created() {
    this.getAllLine();
  },

  mounted() {},
  watch: {
    "formInline.team"(val, oldval) {
      // 班组发生改变时,清空车辆信息后重新获取
      if (JSON.stringify(val) != JSON.stringify(oldval)) {
        this.vehicleOptions = [];
        // 清空排班列表
        if (val.length != 0 && val != "") {
          // 获取车辆信息
          getBusList({ teamIds: this.formInline.team }).then(res => {
            if (res.code == 1000) {
              res.data.forEach(vehicel => {
                this.vehicleOptions.push({
                  value: vehicel.vehicleNo,
                  label: vehicel.cph,
                  isChoosed: false,
                  useLine: ""
                });
              });
            }
          });
        } else {
          // 当车组选项被清空时,将车辆选项列表清空
          this.vehicleOptions = [];
        }
      }
    },
    "formInline.timeRange"(val) {
      if (
        this.formInline.timeRange[0] != "" &&
        this.formInline.timeRange[1] != ""
      ) {
        const tomorrow = new Date(
          new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            new Date().getDate()
        ).getTime();
        // + 24 * 60 * 60 * 1000;
        const nextMonth =
          new Date(
            new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              new Date().getDate()
          ).getTime() +
          90 * 24 * 60 * 60 * 1000;
        if (val[0].getTime() < tomorrow) {
          this.$message.warning("排班时间段起始日期必须为今天以后的日期");
          this.formInline.timeRange[0] = "";
          this.formInline.timeRange[1] = "";
        } else {
          if (val[1].getTime() > nextMonth) {
            this.$message.warning("排班时间范围为90天");
            this.formInline.timeRange[0] = "";
            this.formInline.timeRange[1] = "";
          }
        }
      }
    },
    "formInline.line"(val) {
      this.formInline.lineType = this.lineOptions[
        this.lineOptions.findIndex(line => line.value == val)
      ].lineType;
    }
  }
};
</script>

<style lang="scss" scoped>
.tableArea {
  padding: 1.5vh 0;
  background: #ffffff;
  margin-bottom: 1.5vh;
  .head {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .item {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      &:nth-of-type(2) {
        margin: 0 1vw;
      }
      span {
        padding-left: 1vh;
        width: 6vw;
      }
    }
  }
  .scheduleList {
    position: relative;
    padding-top: 15px;
    .addLine {
      position: absolute;
      bottom: 9px;
      right: 35%;
    }
  }
}

.step {
  margin-bottom: 2vh;
}

.step2 {
  background: rgba($color: #dcdedf, $alpha: 0.1);
  padding: 1.5vh;
}

.step3 {
  height: 50vh;
  background: rgba($color: #dcdedf, $alpha: 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    color: #35e457;
    font-size: 2vh;
    margin-top: 1.5vh;
  }
}

/deep/.el-form-item {
  margin-bottom: 1vh;
}
</style>


