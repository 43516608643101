<template>
  <div class="scheduling-management" ref="schedulingManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button size="small" type="primary" @click="toAdd" v-if="nowMenuList.indexOf('新增') >= 0">新增</el-button>
      <el-button
        size="small"
        type="primary"
        @click="delPlan"
        v-if="nowMenuList.indexOf('删除') >= 0"
      >删除</el-button>
      <el-button
        size="small"
        type="primary"
        @click="addShiftOrTrip"
        v-if="nowMenuList.indexOf('添加班次车次') >= 0"
      >添加班次车次</el-button>
      <el-button
        size="small"
        type="primary"
        @click="changeDriver(1)"
        v-if="nowMenuList.indexOf('换班') >= 0"
      >换班</el-button>
      <el-button
        size="small"
        type="primary"
        @click="changeDriver(0)"
        v-if="nowMenuList.indexOf('顶班') >= 0"
      >顶班</el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="82px"
      >
        <el-form-item label="排班日期：" prop="workDay">
          <el-date-picker
            v-model="form.workDay"
            :clearable="false"
            :editable="false"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="getBanCiListFn"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属线路：" prop="companyId">
          <el-select v-model="form.companyId" placeholder="请选择线路" @change="getBanCiListFn">
            <el-option
              v-for="item in allLines"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运行方向：" prop="moveType">
          <el-select v-model="form.moveType" placeholder="请选择运行方向" clearable>
            <el-option
              v-for="item in moveTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="班次：" prop="banCiId">
          <el-select v-model="form.banCiId" placeholder="请选择班次" clearable>
            <el-option
              v-for="item in banciAllList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button size="small" type="primary" @click="toSearch">查询</el-button>
          <el-button
            size="small"
            type="primary"
            :disabled="checiAllList.length === 0"
            :loading="downLoadStatus"
            @click="exportData"
          >导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      class="basicBox"
      :data="checiAllList"
      stripe
      height="65vh"
      @selection-change="getCheckedData"
    >
      <el-table-column type="selection" width="55" :selectable="checkboxT" disabled="true"></el-table-column>
      <el-table-column label="序号" type="index" width="60"></el-table-column>
      <el-table-column prop="moveType" label="运行方向">
        <template slot-scope="scope">{{ moveTypes.find(t => t.value === scope.row.moveType).label }}</template>
      </el-table-column>
      <el-table-column prop="banCiName" label="班次"></el-table-column>
      <el-table-column prop="beginTime" label="计划发车"></el-table-column>
      <el-table-column prop="endTime" label="计划到达">
        <template slot-scope="scope">{{ scope.row | filterArrive }}</template>
      </el-table-column>
      <el-table-column prop="workTime" label="行驶时长"></el-table-column>
      <el-table-column prop="cph" label="车牌号">
        <template slot-scope="scope">
          <span
            style="color: #01adff; cursor: pointer"
            @click="showScheduling(scope.row)"
          >{{ scope.row.cph }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="driverName" label="司机"></el-table-column>
      <el-table-column label="车次状态">
        <template slot-scope="scope">
          <span v-if="scope.row.adjustStatus == 0" style="color: red">
            {{
            scope.row.adjustStatus | filterAdjustStatus
            }}
          </span>
          <span v-else>{{ scope.row.adjustStatus | filterAdjustStatus }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="natureState" label="变更说明">
        <template slot-scope="scope">
          <span
            v-if="scope.row.natureState > 2 && scope.row.natureState < 6"
            style="color: #0053e1"
          >{{ scope.row.natureState | filterNatureState }}</span>
          <span v-if="scope.row.natureState == 6" style="color: #acb2bc">
            {{
            scope.row.natureState | filterNatureState
            }}
          </span>
          <span v-if="scope.row.natureState == 7" style="color: #2dbff7">
            {{
            scope.row.natureState | filterNatureState
            }}
          </span>
          <span v-if="scope.row.natureState < 3 || scope.row.natureState > 7">
            {{
            scope.row.natureState | filterNatureState
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
    </el-table>

    <el-dialog
      :title="updateform.updateType == 1 ? '换班' : '顶班'"
      :visible.sync="driverdialogVisible"
      v-if="driverdialogVisible"
      width="50%"
    >
      <div class="form search-area">
        <el-form
          :model="updateform"
          status-icon
          :rules="updaterules"
          label-position="left"
          ref="updateform"
          label-width="120px"
          class="demo-form form-search updateform"
        >
          <el-form-item label="方式:" prop="classType" style="width: 400px">
            <el-select v-model="updateform.classType" placeholder="请选择方式">
              <el-option label="车人并换" value="1"></el-option>
              <el-option :label="updateform.updateType == 1 ? '司机换班' : '司机顶班'" value="2"></el-option>
              <el-option :label="updateform.updateType == 1 ? '车辆换班' : '车辆顶班'" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="现车辆:" prop="oldVehicleNo" style="width: 400px">
            <el-select :disabled="true" v-model="updateform.oldVehicleNo">
              <el-option
                v-for="item in nowBusByline"
                :key="item.seqId"
                :label="item.busCode"
                :value="item.seqId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="现司机:" prop="oldCredentialsNo" style="width: 400px">
            <el-select :disabled="true" v-model="updateform.oldCredentialsNo">
              <el-option
                v-for="item in nowDriverByline"
                :key="item.seqId"
                :label="item.driverName"
                :value="item.seqId"
              ></el-option>
            </el-select>
          </el-form-item>
          <div v-if="updateform.updateType == 0">
            <el-form-item
              label="顶班车辆:"
              v-if="updateform.classType != '2'"
              prop="newVehicleNo"
              style="width: 400px"
            >
              <el-select :disabled="updateform.classType == '2'" v-model="updateform.newVehicleNo">
                <el-option
                  v-for="item in allBusByline"
                  :key="item.vehicleNo"
                  :label="item.cph"
                  :value="item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="顶班司机:"
              v-if="updateform.classType != '3'"
              prop="newCredentialsNo"
              style="width: 400px"
            >
              <el-select v-model="updateform.newCredentialsNo">
                <el-option
                  v-for="item in allDriverByline"
                  :key="item.credentialsNo"
                  :label="item.driverName"
                  :value="item.credentialsNo"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="updateform.updateType == 1">
            <el-form-item
              label="换班车辆:"
              v-if="updateform.classType == '3'"
              prop="newVehicleNo"
              style="width: 400px"
            >
              <el-select :disabled="updateform.classType == '2'" v-model="updateform.newVehicleNo">
                <el-option
                  v-for="item in allBusByline"
                  :key="item.vehicleNo"
                  :label="item.cph"
                  :value="item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="换班司机:"
              v-if="updateform.classType == '2'"
              prop="newCredentialsNo"
              style="width: 400px"
            >
              <el-select v-model="updateform.newCredentialsNo">
                <el-option
                  v-for="item in allDriverByline"
                  :key="item.credentialsNo"
                  :label="item.driverName"
                  :value="item.credentialsNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="换班司机-车辆:"
              v-if="updateform.classType == '1'"
              prop="newCredentialsNo"
              style="width: 400px"
            >
              <el-select v-model="updateform.newCredentialsNo">
                <el-option
                  v-for="item in allDriverBusByline"
                  :key="item.credentialsNo+'-'+item.vehicleNo"
                  :label="item.driverName+'-'+item.cph"
                  :value="item.credentialsNo+'-'+item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item
            label="车次:"
            prop="checi"
            style="width: 90%"
            v-if="updateform.updateType != 1"
          >
            <el-table
              class="basicBox"
              :data="dingAllList"
              @selection-change="getDingCheckedData"
              :header-cell-style="{
                background: '#F7F8FA',
                color: '#666666',
                padding: '0'
              }"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="beginTime" label="计划发车"></el-table-column>
              <el-table-column prop="endTime" label="计划到达">
                <template slot-scope="scope">
                  {{
                  scope.row | filterDingArrive
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="moveType" label="发车类型"></el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="driverdialogVisible = false">取消</el-button>
        <el-button size="small" v-if="!btnShow" type="primary" @click="update">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="title" :visible.sync="dialogVisible" v-if="dialogVisible" width="55%">
      <autoSchedulingAdd @closedia="closedia" @canceldia="canceldia" />
    </el-dialog>

    <el-dialog title="单车发班表" :visible.sync="schedulingVisible" v-if="schedulingVisible" width="30%">
      <el-table :data="busPlanList" height="65vh">
        <el-table-column prop="time" :label="busCode">
          <el-table-column
            :prop="banCiMoveType == 2 ? 'down' : 'up'"
            :label="banCiMoveType == 2 ? '主-副' : '副-主'"
          ></el-table-column>
          <el-table-column
            :prop="banCiMoveType == 2 ? 'up' : 'down'"
            :label="banCiMoveType == 2 ? '副-主' : '主-副'"
          ></el-table-column>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 删除计划 -->
    <el-dialog title="删除计划" v-if="delPlanVisible" :visible.sync="delPlanVisible" width="30%">
      <div class="form search-area">
        <el-form
          ref="form"
          :model="delform"
          label-position="left"
          label-width="90px"
          class="demo-form form-search"
        >
          <el-form-item label="变更说明:" prop="natureState">
            <el-select v-model="delform.natureState" placeholder="请选择变更说明">
              <el-option
                v-for="item in natureOptions"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注:" prop="remark">
            <el-input v-model="delform.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="delPlanVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitDelPlan">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加班次/车次 -->
    <el-dialog
      title="添加班次/车次"
      :visible.sync="addShiftOrTripDia"
      width="50%"
      class="addShiftOrTripDia"
      @close="cancleAddShiftOrTripDia"
      @open="addShiftOrTripDia = true"
    >
      <el-tabs type="border-card" v-model="addShiftOrTripRadio" @tab-click="shift2radio">
        <el-tab-pane label="添加班次" name="1">
          <div class="form search-area">
            <el-form
              ref="form"
              :model="addShiftOrTripForm"
              label-position="left"
              label-width="120px"
              :rules="addShiftOrTripRules"
              class="demo-form dialog-form"
            >
              <el-form-item label="线路:" prop="line">
                <el-select v-model="addShiftOrTripForm.line" placeholder="请选择" @change="chooseLine">
                  <el-option
                    v-for="item in allLines"
                    :key="item.companyId"
                    :label="item.companyName"
                    :value="item.companyId"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="日期:" prop="date">
                <el-date-picker
                  v-model="addShiftOrTripForm.date"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="datePickerOptions"
                ></el-date-picker>
              </el-form-item>

              <el-form-item label="车辆:" prop="vehicle">
                <el-select
                  filterable
                  :filter-method="filterMethodFun"
                  v-model="addShiftOrTripForm.vehicle"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in vehicleOptions"
                    :key="item.vehicleNo"
                    :label="item.cph"
                    :value="item.vehicleNo"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="驾驶员:" prop="vehicle">
                <el-select
                  filterable
                  :filter-method="vehicleMethodFun"
                  v-model="addShiftOrTripForm.driver"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in driversOptions"
                    :key="item.credentialsNo"
                    :label="item.driverName"
                    :value="item.credentialsNo"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="发车类型:" prop="type">
                <el-radio v-model="addShiftOrTripForm.type" label="1">主-副</el-radio>
                <el-radio v-model="addShiftOrTripForm.type" label="2" v-if="LineType!== 1">副-主</el-radio>
              </el-form-item>

              <el-form-item label="发车时间:" prop="time">
                <el-time-picker
                  v-model="addShiftOrTripForm.time"
                  value-format="HH:mm"
                  placeholder="请选择发车时间"
                ></el-time-picker>
              </el-form-item>

              <el-form-item label="运行时长(分钟):" prop="runTime" ref="runTime">
                <el-input v-model="addShiftOrTripForm.runTime"></el-input>
              </el-form-item>

              <el-form-item label="休息时长(分钟):" prop="restTime" ref="restTime">
                <el-input v-model="addShiftOrTripForm.restTime"></el-input>
              </el-form-item>

              <el-form-item label="增加趟次:" v-if="addShiftOrTripRadio == 1" prop="addTrips">
                <el-select v-model="addShiftOrTripForm.addTrips" placeholder="请选择">
                  <el-option
                    v-for="item in addTripsOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="添加车次" name="2">
          <div class="form search-area">
            <el-form
              ref="form"
              :model="addShiftOrTripForm"
              label-position="left"
              label-width="120px"
              :rules="addShiftOrTripRules"
              class="demo-form dialog-form"
            >
              <el-form-item label="线路:" prop="line">
                <el-select v-model="addShiftOrTripForm.line" placeholder="请选择" @change="chooseLine">
                  <el-option
                    v-for="item in allLines"
                    :key="item.companyId"
                    :label="item.companyName"
                    :value="item.companyId"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="日期:" prop="date">
                <el-date-picker
                  v-model="addShiftOrTripForm.date"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="datePickerOptions"
                ></el-date-picker>
              </el-form-item>

              <el-form-item label="车辆:" prop="vehicle">
                <el-select
                  filterable
                  :filter-method="filterMethodFun"
                  v-model="addShiftOrTripForm.vehicle"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in vehicleOptions"
                    :key="item.vehicleNo"
                    :label="item.cph"
                    :value="item.vehicleNo"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="驾驶员:" prop="vehicle">
                <el-select
                  filterable
                  :filter-method="vehicleMethodFun"
                  v-model="addShiftOrTripForm.driver"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in driversOptions"
                    :key="item.credentialsNo"
                    :label="item.driverName"
                    :value="item.credentialsNo"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="发车类型:" prop="type">
                <el-radio v-model="addShiftOrTripForm.type" label="1">主-副</el-radio>
                <el-radio v-model="addShiftOrTripForm.type" label="2" v-if="LineType!== 1">副-主</el-radio>
              </el-form-item>

              <el-form-item label="发车时间:" prop="time">
                <el-time-picker
                  v-model="addShiftOrTripForm.time"
                  value-format="HH:mm"
                  placeholder="请选择发车时间"
                ></el-time-picker>
              </el-form-item>

              <el-form-item label="运行时长(分钟):" prop="runTime" ref="runTime">
                <el-input v-model="addShiftOrTripForm.runTime"></el-input>
              </el-form-item>

              <el-form-item label="休息时长(分钟):" prop="restTime" ref="restTime">
                <el-input v-model="addShiftOrTripForm.restTime"></el-input>
              </el-form-item>

              <el-form-item label="增加趟次:" v-if="addShiftOrTripRadio == 1" prop="addTrips">
                <el-select v-model="addShiftOrTripForm.addTrips" placeholder="请选择">
                  <el-option
                    v-for="item in addTripsOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="buttonGroup text-right tabButtonGroup">
        <el-button type="default" size="small" @click="cancleAddShiftOrTripDia">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitShiftOrTrip"
          :disabled="!isSubmitShiftOrTripVisible"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryAllBusLineList,
  getCheCi,
  getBanCi,
  addBanci,
  addCheci,
  shiftChange,
  topShift,
  queryTopShiftVehicle,
  queryTopShiftDriver,
  queryShiftChangeVehicle,
  queryShiftChangeDriver,
  queryShiftVehicleDriver,
  queryTopShiftChiCi,
  getBusPlanList,
  deleteDriverOrBus,
  getBusByline,
  getDriverByline
} from "@/api/lib/bus-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import moment from "moment";
import { mapGetters } from "vuex";
import autoSchedulingAdd from "./autoSchedulingAdd";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { formatDay, formatDate } from "@/common/utils/index";

export default {
  components: {
    autoSchedulingAdd
  },

  data() {
    return {
      form: {
        companyId: null,
        banCiId: null,
        moveType: null,
        workDay: moment(new Date()).format("YYYY-MM-DD")
      },
      updateform: {
        companyId: null,
        oldCredentialsNo: null,
        oldVehicleNo: null,
        newCredentialsNo: null,
        newVehicleNo: null,
        workDay: null,
        classType: "1",
        updateType: null,
        cheCiIds: []
      },
      delform: {
        cheCiIds: [],
        natureState: null,
        remark: null
      },
      natureOptions: [],
      allLines: [],
      busPlanList: [],
      checiAllList: [],
      dingAllList: [],
      banciAllList: [],
      allBusByline: [],
      nowBusByline: [],
      nowDriverByline: [],
      allDriverByline: [],
      allDriverBusByline: [],
      checiByqueryList: [],
      dialogVisible: false,
      delPlanVisible: false,
      schedulingVisible: false,
      averagdialogVisible: false,
      driverdialogVisible: false,
      driverName: "",
      busCode: "",
      banCiMoveType: null,
      vehicleOptions: [],
      driversOptions: [],
      btnShow: false,
      rules: {
        companyId: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      updaterules: {
        companyId: [{ required: true, message: "不能为空", trigger: "change" }],
        oldCredentialsNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        oldVehicleNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        newCredentialsNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        classType: [{ required: true, message: "不能为空", trigger: "change" }],
        newVehicleNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ]
      },
      addrules: {
        companyId: [{ required: true, message: "不能为空", trigger: "change" }],
        addTime: [{ required: true, message: "不能为空", trigger: "change" }],
        busId: [{ required: true, message: "不能为空", trigger: "change" }],
        driverId: [{ required: true, message: "不能为空", trigger: "change" }],
        workDay: [
          { required: true, message: "不能为空", trigger: "change" },
          {
            validator(rule, value, callback) {
              if (/(^[1-9]\d*$)/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正整数"));
              }
            },
            trigger: "blur"
          }
        ],
        restTime: [
          { required: true, message: "不能为空", trigger: "change" },
          {
            validator(rule, value, callback) {
              if (/(^[0-9]\d*$)/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入大于等于0的整数"));
              }
            },
            trigger: "blur"
          }
        ],
        moveType: [{ required: true, message: "不能为空", trigger: "change" }],
        addNums: [{ required: true, message: "不能为空", trigger: "change" }],
        reason: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      pickerOptions: {
        selectableRange: "00:00:00 - 23:59:59"
      },
      addShiftOrTripDia: false, // 添加车次/班次弹窗
      isSubmitShiftOrTripVisible: false,
      addShiftOrTripRadio: "1",
      addShiftOrTripForm: {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "",
        restTime: "",
        addTrips: "0.5",
        driver: ""
      },
      addShiftOrTripRules: {
        runTime: [
          {
            validator(rule, value, callback) {
              if (/(^[0-9]\d*$)/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入大于等于0的整数"));
              }
            },
            trigger: "change"
          }
        ]
      },
      addTripsOptions: [
        { value: 0.5, label: "0.5" },
        { value: 1, label: "1" },
        { value: 1.5, label: "1.5" },
        { value: 2, label: "2" },
        { value: 2.5, label: "2.5" },
        { value: 3, label: "3" },
        { value: 3.5, label: "3.5" },
        { value: 4, label: "4" },
        { value: 4.5, label: "4.5" },
        { value: 5, label: "5" }
      ],
      datePickerOptions: {
        disabledDate(time) {
          return (
            time <
            new Date(new Date(`${formatDay(new Date())} 00:00:00`).getTime())
          );
        }
      },
      moveTypes: [
        { value: 1, label: "上行" },
        { value: 2, label: "下行" }
      ],
      downLoadStatus: false,
      LineType:1
    };
  },
  computed: {
    ...mapGetters({
      nowMenuList: "nowMenuList"
    })
  },
  methods: {
    vehicleMethodFun(text) {
      getDriverByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
      });
    },
    // 车辆模糊查询
    filterMethodFun(text) {
      getBusByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.vehicleOptions = res.data;
        }
      });
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.schedulingManagement.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - buttonsHeight - 16 - formHeight - 10 - 16;
    },
    onResetForm() {
      this.form = {
        companyId: this.allLines[0].companyId,
        banCiId: null,
        moveType: null,
        workDay: moment(new Date()).format("YYYY-MM-DD")
      };
      this.toSearch();
      this.getBanCiListFn();
    },
    // 显示新增排班计划弹窗
    toAdd() {
      this.title = "新增排班计划";
      this.dialogVisible = true;
    },

    // 关闭新增排班计划弹窗
    closedia(val) {
      this.form.companyId = val.line;
      // this.form.workDay = "";
      this.getBanCiListFn();
      this.toSearch();
      this.dialogVisible = false;
    },

    canceldia() {
      this.dialogVisible = false;
    },

    // 选中checkbox
    getCheckedData(val) {
      if (val.length == 1) {
        this.updateform.oldCredentialsNo = val[0].credentialsNo;
        this.updateform.oldVehicleNo = val[0].vehicleNo;
        this.nowBusByline = [{ busCode: val[0].cph, seqId: val[0].vehicleNo }];
        this.nowDriverByline = [
          { driverName: val[0].driverName, seqId: val[0].credentialsNo }
        ];
      }
      this.updateform.cheCiIds = this.delform.cheCiIds = val.map(
        ele => ele.checiId
      );
    },

    // 顶班选中checkbox
    getDingCheckedData(val) {
      this.updateform.cheCiIds = val.map(ele => ele.cheCiId);
    },

    //禁用计划状态为删除的复选框
    checkboxT(row, index) {
      if (row.adjustStatus == 0) {
        return false;
      } else {
        return true;
      }
    },

    update() {
      var self = this;
      self.$refs.updateform.validate(valid => {
        if (valid) {
          self.updateform.workDay = self.form.workDay;
          if (self.updateform.classType == 2) {
            self.updateform.newVehicleNo = null;
          } else if (self.updateform.classType == 3) {
            self.updateform.newCredentialsNo = null;
          } else if (
            self.updateform.classType == 1 &&
            self.updateform.updateType == 1
          ) {
            self.updateform.newVehicleNo = self.updateform.newCredentialsNo.split(
              "-"
            )[1];
            self.updateform.newCredentialsNo = self.updateform.newCredentialsNo.split(
              "-"
            )[0];
          }
          if (self.updateform.updateType == 1) {
            shiftChange(self.updateform).then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
                this.getCheCiListFn();
                this.driverdialogVisible = false;
              } else {
                this.updateform.newCredentialsNo = "";
                this.$message.error(res.msg);
              }
            });
          } else {
            topShift(self.updateform).then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
                this.getCheCiListFn();
                this.driverdialogVisible = false;
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },

    // 调整时间
    changeTime(val) {
      updateWorkTripTime({
        tripTime: [
          {
            newBeginTime: moment(val.beginTime).format("HH:mm"),
            checiId: val.seqId
          }
        ]
      })
        .then(res => {
          this.$message.success("操作成功");
          this.getCheCiListFn();
        })
        .catch(error => {
          console.log(error);
        });
    },

    //导出
    exportData() {
      this.downLoadStatus = true;
      let data = {
        baseUrl: "bus",
        userId: sessionStorage.getItem("userId")||localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `计划管理`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.form),
        queryPath: "/schedulingManage/planManageExport"
      };
      addExcelExport(data)
        .then(res => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },

    toSearch() {
      this.getCheCiListFn();
    },

    // 打开换班顶班弹窗
    changeDriver(updateType) {
      if (this.form.companyId == null) {
        this.$message.warning("请选择所属线路！");
      } else {
        if (this.updateform.cheCiIds.length != 1) {
          this.$message.warning("请选择单个车次进行换班或顶班！");
        } else {
          this.updateform.companyId = this.form.companyId;
          this.updateform.updateType = updateType;
          this.updateform.newCredentialsNo = null;
          this.updateform.newVehicleNo = null;
          this.checBusDriverByline();
          this.getDingListFn();
          this.driverdialogVisible = true;
        }
      }
    },

    // 打开单车发班表弹窗
    showScheduling(row) {
      let self = this;
      let data = {
        vehicleNo: row.vehicleNo,
        planType: 1,
        workDay: self.form.workDay
      };
      getBusPlanList(data)
        .then(res => {
          self.busPlanList = [];
          self.busCode = res.data.cph;
          self.banCiMoveType = res.data.banCiMoveType;
          // self.busPlanList = res.data.planList;
          res.data.planList.forEach(item => {
            if (!(item.down == "" && item.up == "")) {
              self.busPlanList.push(item);
            }
          });
          self.schedulingVisible = true;
        })
        .catch(error => {});
    },

    // 删除计划弹窗
    delPlan() {
      this.delPlanVisible = true;
      queryDictsByCodes({ parentCodes: "BUSLX" }).then(res => {
        if (res.code === 1000) {
          this.natureOptions = res.data.BUSLX;
        }
      });
    },

    // 删除计划确定按钮
    submitDelPlan() {
      var self = this;
      deleteDriverOrBus(self.delform)
        .then(res => {
          if (res.code === 1000) {
            this.$message.success("操作成功");
            this.delPlanVisible = false;
            this.getCheCiListFn();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // 所有的线路下拉菜单
    getAllLineListFn() {
      queryAllBusLineList({}).then(res => {
        if (res.data.length == 0) {
          this.$message.warning("还没有线路信息，请先添加线路信息");
        } else {
          this.allLines = res.data;
          this.onResetForm();
        }
      });
    },

    // 显示班次下拉菜单
    getBanCiListFn() {
      var self = this;
      var data = {
        companyId: self.form.companyId,
        workDay: self.form.workDay
      };
      this.form.banCiId = "";
      self.banciAllList = [];
      getBanCi(data).then(res => {
        self.banciAllList = res.data;
        if (res.data.length !== 0) {
          // self.banciAllList.unshift({ name: '全部', id: null })
          // self.getCheCiListFn();
        } else {
          self.$message.warning("当前线路暂无排班,请先排班");
        }
      });
    },

    // 显示车次列表
    getCheCiListFn() {
      var self = this;
      var data = {
        companyId: self.form.companyId,
        workDay: self.form.workDay,
        moveType: self.form.moveType,
        banCiId: self.form.banCiId
      };
      getCheCi(data).then(res => {
        self.checiAllList = res.data;
      });
    },

    // 根据驾驶员、车辆和日期查询车次
    getDingListFn() {
      var self = this;
      var data = {
        workDay: self.form.workDay,
        credentialsNo: self.updateform.oldCredentialsNo,
        vehicleNo: self.updateform.oldVehicleNo
      };
      queryTopShiftChiCi(data).then(res => {
        if (res.code === 1000) {
          self.dingAllList = res.data;
          self.dingAllList.map(item => {
            if (item.moveType == 2) {
              item.moveType = "主-副";
            } else {
              item.moveType = "副-主";
            }
          });
        } else {
          self.$message.error(res.msg);
        }
      });
    },

    //根据车辆获取对应日期排班中的驾驶员
    // getArrangedDriverByBus () {
    //   var self = this
    //   var data = {
    //     busId: self.updateform.newVehicleNo,
    //     workDay: self.form.workDay
    //   }
    //   getArrangedDriverByBus(data).then((res) => {
    //     if (res.data.length != 1) {
    //       self.updateform.newCredentialsNo = ''
    //       if (self.updateform.updateType == 1) {
    //         self.$message.warning('车人并换只能换单个驾驶员，请重新选择车辆')
    //       }
    //     } else {
    //       self.updateform.newCredentialsNo = res.data[0].seqId
    //     }
    //   })
    // },

    // 显示可选司机和车辆列表
    checBusDriverByline() {
      var self = this;
      var data = {
        workDay: self.form.workDay,
        cheCiIds: self.updateform.cheCiIds
      };
      self.allBusByline = [];
      self.allDriverByline = [];
      if (self.updateform.updateType == 0) {
        queryTopShiftVehicle(data).then(res => {
          self.allBusByline = res.data;
          if (res.data.length == 0) {
            self.$message.warning("当前时间无车辆可选择");
          }
        });
        queryTopShiftDriver(data).then(res => {
          self.allDriverByline = res.data;
          if (res.data.length == 0) {
            self.$message.warning("当前时间无司机可选择");
          }
        });
      } else {
        queryShiftVehicleDriver(data).then(res => {
          self.allDriverBusByline = self.allBusByline = self.allDriverByline =
            res.data;
        });
        queryShiftChangeVehicle(data).then(res => {
          self.allBusByline = res.data;
          if (res.data.length == 0) {
            self.$message.warning("当前时间无车辆可选择");
          }
        });
        queryShiftChangeDriver(data).then(res => {
          self.allDriverByline = res.data;
          if (res.data.length == 0) {
            self.$message.warning("当前时间无司机可选择");
          }
        });
      }
    },
    // 添加班次/车次
    addShiftOrTrip() {
      this.addShiftOrTripDia = true;
    },
    // 添加班次/车次弹窗切换班次/车次
    shift2radio() {
      this.LineType=1;
      this.addShiftOrTripForm = {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "",
        restTime: "",
        addTrips: "0.5",
        driver: ""
      };
      this.$refs.runTime.resetField();
      this.vehicleOptions = [];
      this.driversOptions = [];
    },
    // 提交新增班次/车次表单
    submitShiftOrTrip() {
      let data = {
        addTime: this.addShiftOrTripForm.date,
        beginTime: this.addShiftOrTripForm.time,
        vehicleNo: this.addShiftOrTripForm.vehicle,
        credentialsNo: this.addShiftOrTripForm.driver,
        companyId: this.addShiftOrTripForm.line,
        moveType: this.addShiftOrTripForm.type == 1 ? 2 : 1, // 发车类型，主-副(2),副-主(1)
        restTime: this.addShiftOrTripForm.restTime, // 休息时长，分钟数
        workTime: this.addShiftOrTripForm.runTime // 运行时长，分钟数
      };
      // 添加班次
      if (this.addShiftOrTripRadio == 1) {
        data = {
          ...data,
          addNums: this.addShiftOrTripForm.addTrips
        };
        addBanci(data).then(res => {
          if (res.code == 1000) {
            this.$message.success("新增班次成功!");
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      // 添加车次
      else {
        addCheci(data).then(res => {
          if (res.code == 1000) {
            this.$message.success("新增车次成功!");
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      this.cancleAddShiftOrTripDia();
      this.getBanCiListFn();
      this.$refs.form.clearValidate();
    },
    // 关闭增加班次/车次弹窗
    cancleAddShiftOrTripDia() {
      this.addShiftOrTripDia = false;
      // 重置弹窗数据
      this.addShiftOrTripRadio = "1";
      this.LineType=1;
      this.addShiftOrTripForm = {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "0",
        restTime: "0",
        addTrips: "0.5",
        driver: ""
      };
      this.vehicleOptions = [];
      this.driversOptions = [];
    },
    // 添加班次/车次选择线路时获取可选车辆信息和司机信息
    chooseLine(e) {
      // 在选择线路时,先将表格中车辆和驾驶员清空
      this.allLines.map(item=>{
        if(item.companyId===e){
          this.LineType = item.lineType
        }
      })
      this.addShiftOrTripForm.driver = "";
      this.addShiftOrTripForm.vehicle = "";
      if (this.addShiftOrTripForm.line) {
        const data = {
          companyId: this.addShiftOrTripForm.line
        };
        getBusByline(data).then(res => {
          if (res.code == 1000) {
            this.vehicleOptions = res.data;
          }
        });
        getDriverByline(data).then(res => {
          if (res.code == 1000) {
            this.driversOptions = res.data;
          }
        });
      }
    }
  },
  created() {
    this.getAllLineListFn();
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  watch: {
    addShiftOrTripForm: {
      handler(val) {
        // 非空校验
        if (
          val.line &&
          val.date &&
          val.vehicle &&
          val.type &&
          val.time &&
          val.driver
        ) {
          // 添加班次
          if (this.addShiftOrTripRadio == 1) {
            if (val.runTime && val.addTrips) {
              this.isSubmitShiftOrTripVisible = true;
            } else {
              this.isSubmitShiftOrTripVisible = false;
            }
          } else {
            // 添加车次
            if (val.runTime) {
              this.isSubmitShiftOrTripVisible = true;
            } else {
              this.isSubmitShiftOrTripVisible = false;
            }
          }
        } else {
          this.isSubmitShiftOrTripVisible = false;
        }
      },
      deep: true
    }
  },
  filters: {
    filterAdjustStatus(item) {
      return item == 0 ? "删除" : "正常";
    },
    filterNatureState(item) {
      if (item == 3) {
        return "加油";
      } else if (item == 4) {
        return "加气";
      } else if (item == 5) {
        return "充电";
      } else if (item == 6) {
        return "维修";
      } else if (item == 7) {
        return "包车";
      } else if (item == 128) {
        return "其他";
      } else if (item == 9) {
        return "恢复运营";
      } else {
        return "正常";
      }
    },
    filterArrive(item) {
      let timestamp =
        new Date(
          item.workDay.split(" ")[0] + " " + item.beginTime + ":00"
        ).getTime() +
        60000 * item.workTime;
      return moment(timestamp).format("HH:mm");
    },
    filterDingArrive(item) {
      let timestamp =
        new Date(new Date(`2019-01-01 ${item.beginTime}:00`)).getTime() +
        60000 * item.workTime;
      return moment(timestamp).format("HH:mm");
    }
  }
};
</script>

<style lang="scss" scoped>
.scheduling-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
  }
}

.theme-project-bus {
  .scheduling-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>

